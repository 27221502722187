.heightMain {
  height: 900px;
}
.mainWidth {
  width: 67rem;
}
.buttonHeight {
  height: 450px;
}
.height {
  height: 230px;
}
.width {
  width: 16rem;
}
.heightOffice {
  height: 100px;
}
.widthOffice {
  width: 295px;
}
.buttonWidth {
  width: 62rem;
}
.paddingHeader {
  padding-left: 6.5rem;
  padding-right: 6.5rem;
}

html {
  font-family: "Mundial";
}

@font-face {
  font-family: "Mundial"; /*Can be any text*/
  src: local("MundialRegular"),
    url("./assets/font/mundial-cufonfonts/MundialRegular.otf")
      format("truetype");
}
